@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.6rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer base {
  html,
  body,
  #root {
    @apply h-full w-full;
  }

  h1 {
    @apply text-5xl font-bold my-6;
  }
  h2 {
    @apply text-4xl font-bold my-6;
  }

  h4 {
    @apply text-3xl font-bold my-4;
  }

  p {
    @apply text-3xl my-2 font-[300];
  }
}

@layer components {
  .container {
    @apply py-[5vh] px-[10vw] flex flex-col max-w-full w-full h-full overflow-y-auto dark:bg-black dark:text-white;
  }
}

@layer utilities {
  .scrollbar::-webkit-scrollbar-track {
    display: hidden;
  }

  .scrollbar::-webkit-scrollbar {
    width: 1rem;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 0.1rem solid slategrey;
    border-radius: 1rem;
  }
}
